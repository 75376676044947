import { ThemeProvider, AppContainer, SnackbarProvider } from "@packages/theme-mui-v5";
import CaseManagementSpaConfig from "./config";
import { ApiProvider } from "@packages/service-api";
import { initializeSentry } from "@packages/sentry";
import { ReactNode } from "react";
import { CaseManagementProviders } from "@packages/case-management";
import { CaseManagementSpaAuthTokenProvider } from "./CaseManagementSpaAuthTokenProvider";

initializeSentry(CaseManagementSpaConfig.name);

export default function CaseManagementSpaProviders({ children }: { children: ReactNode }) {
  return (
    <CaseManagementProviders>
      <ApiProvider>
        <AppContainer appConfig={CaseManagementSpaConfig}>
          <CaseManagementSpaAuthTokenProvider>
            <ThemeProvider>
              <SnackbarProvider>{children}</SnackbarProvider>
            </ThemeProvider>
          </CaseManagementSpaAuthTokenProvider>
        </AppContainer>
      </ApiProvider>
    </CaseManagementProviders>
  );
}
