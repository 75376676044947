import { useMutation } from "@tanstack/react-query";
import { CaseManagementForm } from "./CaseManagementForm";
import { client, postCasesMutation } from "@packages/case-management-queries";
import { useSnackbar } from "@packages/theme-mui-v5";

type PresignedUploadFile = {
  presignedUrl: string;
  file: File;
};

const fileToBlob = async (file) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

function useFileUploadMutation() {
  return useMutation({
    mutationFn: async ({ presignedUrl, file }: PresignedUploadFile) => {
      const body = await fileToBlob(file);

      // TODO fix file upload, perhaps need multipart / FormData
      // see `file-upload.ts` example
      const response = await client.post({
        baseUrl: "",
        url: presignedUrl,
        body,
        headers: { "Content-Type": file.type ?? "application/octet-stream" }
      });

      return response;
    }
  });
}

export function CaseManagementAddPage() {
  const upload = useFileUploadMutation();
  const createCase = useMutation(postCasesMutation());
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CaseManagementForm
      isBusy={createCase.isPending}
      onSubmit={(values, newAttachments) => {
        createCase.mutate(
          { body: values },
          {
            onSuccess: async () => {
              // TODO response type of create case does not include caseFiles relation
              const uploadedCaseFiles = [];
              // const uploadedCaseFiles = (response.data as any)?.caseFiles || [];

              // TODO actual file uploads after successful creation to presigned URLs
              for (const [index, uploadedCaseFile] of uploadedCaseFiles.entries()) {
                const presignedUrl = uploadedCaseFile.attachmentLocation as string;
                const newAttachment = newAttachments.at(index);
                if (newAttachment.attachmentType !== "Link") {
                  await upload.mutateAsync({
                    presignedUrl,
                    file: newAttachment.attachment
                  });
                }
              }

              enqueueSnackbar("Case created", { variant: "success" });
            },
            onError: () => {
              enqueueSnackbar("Error creating case", { variant: "error" });
            }
          }
        );
      }}
    />
  );
}
