import { useQuery, UseQueryOptions } from "react-query";
import { AcceleratorApi } from "../../../apis";
import { Asset, AssetSearchResponse, SearchApiParams } from "../../../types";

export function useAssetSearchQuery(
  params: SearchApiParams<Asset>,
  options: UseQueryOptions<AssetSearchResponse> = {}
) {
  return useQuery({
    queryKey: ["assets", "search", params] as [string, string, SearchApiParams<Asset>], // TODO variables
    queryFn: ({ queryKey: [, , params] }) => AcceleratorApi.Assets.searchAssets(params),
    enabled: !!params.searchTerm,
    ...options
  });
}
