import { useQuery, UseQueryOptions } from "react-query";
import { AcceleratorApi } from "../../../apis";
import { SearchApiParams, Tag, TagSearchResponse } from "../../../types";

export function useTagsAutocompleteQuery(
  params: SearchApiParams<Tag>,
  options: UseQueryOptions<TagSearchResponse> = {}
) {
  return useQuery({
    queryKey: ["tags", "search", params] as [string, string, SearchApiParams<Tag>], // TODO variables
    queryFn: ({ queryKey: [, , params] }) => AcceleratorApi.Tags.search(params),
    enabled: !!params.searchTerm,
    ...options
  });
}
