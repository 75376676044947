import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { PageContainer } from "@packages/theme-mui-v5";
import { CaseManagementAddPage, CaseManagementDataGridPage } from "@packages/case-management";
import CaseManagementConfig, { CaseManagementCRUDDefinitions } from "./config";
import ApplicationAccessManagement, {
  ApplicationAccessPermissionsProvider
} from "@packages/application-access-management";

const AllRoutes = () => {
  const { pathname } = useLocation();
  return (
    <PageContainer title={""} hideLocationbar={!pathname.includes("admin/auth/")}>
      <Routes>
        <Route path="/search" element={<CaseManagementDataGridPage />} />
        <Route path="/create" element={<CaseManagementAddPage />} />
        <Route
          path="admin/*"
          element={
            <ApplicationAccessPermissionsProvider cruds={CaseManagementCRUDDefinitions}>
              <ApplicationAccessManagement
                hidePageContainer={true}
                basePath={CaseManagementConfig.path.value}
                applicationId={CaseManagementConfig.applicationId}
              />
            </ApplicationAccessPermissionsProvider>
          }
        />
      </Routes>
    </PageContainer>
  );
};

export default function CaseManagementSpaRouter() {
  return (
    <BrowserRouter basename={CaseManagementConfig.path.value}>
      <AllRoutes />
    </BrowserRouter>
  );
}
