/**
 * @TODO the contents of this file will eventually move to a reusable package
 * such as `@packages/react-form-fields`
 */
import type { DeepKeys, DeepValue, FieldApi } from "@tanstack/react-form";

export type FieldStateMUIProps<Value> = {
  value: Value;
  onChange: (value: Value) => void;
} & CommonFieldStateProps;

export type CommonFieldStateProps = {
  error?: boolean;
  helperText?: string;
  required?: boolean;
};

/**
 * Takes a `@tanstack/react-form` field and returns the props needed for MUI components.
 * If you need to override `value` or `onChange`, you can override the prop on the MUI component,
 * by placing them after the spread operator.
 *
 * @example
 * // most common usage
 * <AutocompleteField {...getFieldStateMUIProps(field)} />
 *
 * @example
 * <AutocompleteField
 *   {...getFieldStateMUIProps(field)}
 *
 *   // the above returns most common usage where component expects `string` and matches parent state
 *   // however you can override and shim for cases where component expects differnt value than what is in the parent state
 *   value={String(field.state.value)}
 *   onChange={(value) => field.handleChange(Number(value))}
 * />
 */
export function getFieldStateMUIProps<Data, Name extends DeepKeys<Data>>(
  field: FieldApi<Data, Name, any, any> // eslint-disable-line @typescript-eslint/no-explicit-any
): FieldStateMUIProps<DeepValue<Data, Name>> {
  const error = field.state.meta.errors.length > 0 && field.state.meta.isTouched;
  const helperText = field.state.meta.errors.join(", ");
  const value = field.state.value;
  const onChange = field.handleChange;
  return {
    error,
    helperText,
    value,
    onChange
  };
}
