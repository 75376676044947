import {
  QueryClient as TanstackQueryClient,
  QueryClientProvider as TanstackReactQueryClientProvider
} from "@tanstack/react-query";
import { ReactNode } from "react";

import { client } from "@packages/case-management-queries";
import MessageBus from "@packages/message-bus";

MessageBus.subscribe("auth.session", (message, { session }) => {
  const accessToken = session.accessToken.jwtToken;
  client.setConfig({ headers: { Authorization: `Bearer ${accessToken}` } });
});

/** For supporting `@packages/case-management-queries` */
const tanstackQueryClient = new TanstackQueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5 // 5 minutes
      // cacheTime: 1000 * 60 * 30 // 30 minutes
    }
  }
});

export function CaseManagementProviders({ children }: { children: ReactNode }) {
  return (
    <TanstackReactQueryClientProvider client={tanstackQueryClient}>
      {children}
    </TanstackReactQueryClientProvider>
  );
}
