/**
 * This is a temporary file for any re-usable autocomplete component.
 * Currently, some autocompletes such as `SearchAssets` are defined within `apps/accelerator/*`.
 *
 * These should eventually be moved to a reusable library packages such as
 * `@packages/mui-smart-fields` or something like this, so other packages / apps can import it.
 *
 * The new future package would be for "smart components" that integrate with APIs within.
 * Similar to "molecular" components in atomic design.
 *
 * The core theme package `@packages/theme-mui-v5` should only be core / dumb components with styling.
 * Similar to "atomic" components in atomic design.
 */

import * as React from "react";

import {
  Autocomplete,
  Box,
  CircularProgress,
  TextFieldRaw as TextField,
  useDebounce
} from "@packages/theme-mui-v5";

import { Asset, Tag, useAssetSearchQuery, useTagsAutocompleteQuery } from "@packages/service-api";
import { useMemo } from "react";
import { CommonFieldStateProps } from "./ReactFormFields";

export type AutocompleteAssetsProps = {
  siteId: string;
  value: Asset["id"];
  onChange: (value: Asset["id"], asset: Asset) => void;
} & CommonFieldStateProps;

export function AutocompleteAssets({
  siteId,
  value,
  onChange,
  ...autocompleteProps
}: AutocompleteAssetsProps) {
  const [searchInput, setSearchInput] = React.useState<string>("");
  const searchInputDebounced = useDebounce(searchInput, 200);
  const query = useAssetSearchQuery({ searchTerm: searchInputDebounced, filter: { siteId } });
  const options = useMemo(() => query.data?.data || [], [query.data]);

  /**
   * Maps the given `value` (assetId) to a matching fetched option.
   */
  const valueMappedOption = useMemo(() => {
    // TODO handling initial value not yet loaded
    return options.find((option) => option.id === value) || null;
  }, [value, options]);

  return (
    <AutocompleteBase
      label="Asset"
      primaryKey="id"
      options={options}
      value={valueMappedOption}
      onChange={onChange}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      {...autocompleteProps}
      ListboxOptionComponent={({ option }) => (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", fontSize: 14 }}>
          <Box component="b" sx={{ mr: 2 }}>
            {option?.assetCode}
          </Box>
          <Box sx={{ fontSize: 10 }}>{option?.description}</Box>
        </Box>
      )}
      getOptionString={(option) => `${option.assetCode} - ${option.description}`}
    />
  );
}

export type AutocompleteTagsProps = {
  value: Tag["id"];
  onChange: (value: Tag["id"], tag: Tag) => void;
} & CommonFieldStateProps;

export function AutocompleteTags({ value, onChange, ...autocompleteProps }: AutocompleteTagsProps) {
  const [searchInput, setSearchInput] = React.useState<string>("");
  const searchInputDebounced = useDebounce(searchInput, 200);
  const query = useTagsAutocompleteQuery({ searchTerm: searchInputDebounced });
  const options = useMemo(() => query.data?.data || [], [query.data]);
  const valueMappedOption = useMemo(() => {
    // TODO handling initial value not yet loaded
    //   const { tag: initialTag, loading: initialTagLoading } = useGetTagById(initialTagId);
    return options.find((option) => option.id === value) || null;
  }, [value, options]);

  return (
    <AutocompleteBase
      label="Tag"
      primaryKey="id"
      options={options}
      value={valueMappedOption}
      onChange={onChange}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      {...autocompleteProps}
      ListboxOptionComponent={({ option }) => (
        <Box data-testid="ListboxOptionComponent" sx={{ wordWrap: "break-word" }}>
          <Box component="span">{option.name}</Box>
        </Box>
      )}
      getOptionString={(option) => option.name}
    />
  );
}

type AutocompleteBaseProps<Value extends object> = CommonFieldStateProps & {
  label: string;
  // helperText: string;
  // error: boolean;
  // required?: boolean;

  /** Used to map value to options.  This value must exist on all of your options. */
  primaryKey: keyof Value;

  searchInput: string;
  setSearchInput: (value: string) => void;

  onChange: (value: string, option: Value) => void;

  loading?: boolean;

  value: Value;
  options: Array<Value>;

  /**
   * How the option will be rendered in the listbox dropdown.
   */
  ListboxOptionComponent: React.FunctionComponent<{ option: Value }>;

  /**
   * How the selected option will be rendered in the input field.
   */
  getOptionString: (option: Value) => string;
};

/**
 * This function attempts to reduce boilerplate for creating an autocomplete component.
 */
function AutocompleteBase<Value extends object>({
  value,
  options,
  label,
  helperText,
  error,
  searchInput,
  setSearchInput,
  primaryKey,
  loading = false,
  onChange,
  ListboxOptionComponent,
  getOptionString,
  required
}: AutocompleteBaseProps<Value>) {
  return (
    <Autocomplete
      value={value}
      options={options}
      inputValue={searchInput}
      onInputChange={(event, value) => setSearchInput(value)}
      loading={loading}
      // common ux & styling
      clearOnBlur={false}
      multiple={false}
      noOptionsText="No result found."
      size="small"
      ListboxProps={{ style: { maxHeight: "60vh" } }}
      filterOptions={(options) => options} // don't do client-side filtering
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            sx={{ mb: 0, minWidth: 100 }}
            label={label}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            helperText={helperText}
            error={!!error}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        );
      }}
      onChange={(event, value) => {
        if (value) {
          onChange(value[primaryKey] as string, value);
        } else {
          onChange("", null);
        }
      }}
      isOptionEqualToValue={(option, value) => option[primaryKey] === value[primaryKey]}
      renderOption={(props, option) => (
        <li {...props} key={option[primaryKey] as string}>
          <ListboxOptionComponent option={option} />
        </li>
      )}
      getOptionLabel={(option) => {
        return option ? getOptionString(option) : "";
      }}
    />
  );
}
